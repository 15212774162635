<div class="flex flex-col gap-1">
  <h5>{{ to["label"] }}</h5>

  @if(to["explanation"] && formIsSubmit() && !to["showExplanation"]){
  <mat-slide-toggle [checked]="showExplanation" (change)="showExplanation = !showExplanation" color="primary"
    [class]="showExplanation? 'mt-3' : 'my-3'">
    <b class=" dark:text-white">Afficher l'explication </b>
  </mat-slide-toggle>
  }
  @if(to["explanation"] && formIsSubmit() && (to["showExplanation"] || showExplanation)){
  <mat-card class="mat-elevation-z0 py-1 px-2 my-3 glass-card">
    <mat-card-content class="flex flex-col gap-2">
      <div class="flex gap-3 items-center">
        <mat-icon svgIcon="question-mark-circle-light" color="accent"></mat-icon>
        <h5 class="mb-0">Explication des résultats :</h5>
      </div>
      <mat-divider></mat-divider>

      <p class="  text-black dark:text-white font-light">
        {{ to["explanation"] }}
      </p>
    </mat-card-content>
  </mat-card>
  }

  <mat-selection-list [multiple]="to['multiple'] ?? false" #filterSelectionCard (selectionChange)="
      onSelection($event, filterSelectionCard.selectedOptions, props)
    " class="qcm-selection-list flex flex-wrap" [disableRipple]="true">
    @for (data of getOptions(props.options); track data;) {
    @if(props.type=="card"){
    <div class="basis-full lg:basis-1/2 p-2 flex align-stretch">
      <mat-card class="mat-elevation-z0 p-0 w-full glass-card cursor-pointer min-h-32"
        [ngClass]="showAnswers(data.value, props['css'])" (click)="addOrDeleteToForm(data)">
        <mat-card-content class="flex justify-center items-center h-full ">
          <span>{{data.label}}</span>
        </mat-card-content>
      </mat-card>
    </div>
    }@else{
    <mat-list-option [value]="data" togglePosition="before" color="primary">{{
      data.label
      }}</mat-list-option>
    } }
  </mat-selection-list>

</div>