<mat-card class="chapter-card">
  <img class="chapter-img" [ngClass]="{ 'saturate-0': gameCardConfig.isLocked }" mat-card-image
    [src]="gameCardConfig.backgroundImage" />
  <mat-card-content class="p-6">
    <div class="flex flex-col items-center justify-center h-20">
      <div *ngIf="gameCardConfig.isLocked || gameCardConfig.isCompleted"
        [ngClass]="{'bg-gray-400': gameCardConfig.isLocked, 'bg-green-500': gameCardConfig.isCompleted}"
        class="flex items-center justify-center -translate-y-16 absolute rounded-full h-16 w-16 bg-gray-400 ring-1 ring-gray-200 mb-4">
        <mat-icon *ngIf="gameCardConfig.isCompleted" class="scale-150" svgIcon="checkmark-light"></mat-icon>
        <mat-icon *ngIf="gameCardConfig.isLocked" class="scale-150" svgIcon="lock-bulk"></mat-icon>
      </div>


      <!-- [ngStyle]="{ color: gameCardConfig.isLocked ? 'rgba(102, 112, 133, 1)' : gameCardConfig.fontColor }" -->
      <h4 class="text-sm sm:text-base dark:mat-accent-color" *ngIf="gameCardConfig.showGameChapterTitle.enable">
        Chapitre: {{ gameCardConfig.showGameChapterTitle.order }}
      </h4>
      <!-- [ngStyle]="{ color: gameCardConfig.isLocked  ? 'rgba(102, 112, 133, 1)' : gameCardConfig.fontColor }" -->
      <p class="text-xs text-center sm:text-base dark:text-white" *ngIf="gameCardConfig.showGameChapterTitle.enable">{{
        gameCardConfig.showGameChapterTitle.title }} </p>
    </div>
  </mat-card-content>
</mat-card>