import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { ApiService } from '../api/api.service';
import { Game } from '../models/game.model';
import { searchConfig } from '../models/search.config.model';

const routes = {
  game: (id: string) => `/games/${id}`,
  gameAsUser: (id: string) => `/games/${id}/as-user`,
  games: '/games',
  gamesFromJson: (orgId: string) => '/games/json?orgId=' + orgId,
  gamesForUser: (userId: string) => `/games/users/${userId}`,
  gameParticipants: (gameId: string) => `/games/${gameId}/participants`,
  gameManagers: (gameId: string) => `/games/${gameId}/managers`,
  gameMascotImg: (id: string) => `/games/${id}/mascot`,
  gameBackgroundImg: (id: string) => `/games/${id}/background`,
  gameCardBackgroundImg: (id: string) => `/games/${id}/card-background`,
  gameProfilingIcon: (id: string) => `/games/${id}/profiling-level-icon`,
  gameQuestionIcon: (id: string) => `/games/${id}/question-level-icon`,
  gameStoryIcon: (id: string) => `/games/${id}/story-level-icon`,
  gameLockedIcon: (id: string) => `/games/${id}/locked-level-icon`,
  gameBackgroundStoryPage: (id: string) => `/games/${id}/story-background`,
  gameBackgroundProgressPage: (id: string) =>
    `/games/${id}/progress-background`,
  gameBackgroundRankingPage: (id: string) => `/games/${id}/ranking-background`,
  gameResultPhrase: (id: string) => `/games/${id}/result-phrase`,
  gameInstances: (id: string) => `/games/${id}/instances`,
};

@Injectable({
  providedIn: 'root',
})
export class GameService {
  private urlApi = environment.apiURL;

  constructor(private apiService: ApiService, private http: HttpClient) { }

  getOne(id: string): Observable<Game> {
    return this.apiService.get(routes.game(id));
  }

  getOneAsUser(id: string): Observable<Game> {
    return this.apiService.get(routes.gameAsUser(id));
  }

  getAll(): Observable<Game[]> {
    return this.apiService.get(routes.games).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getAllForUser(userId: string): Observable<Game[]> {
    return this.apiService.get(routes.gamesForUser(userId)).pipe(
      map((data) => {
        return data;
      })
    );
  }

  updateGame(gameId: string, game: any) {
    return this.apiService.patch(`/games/${gameId}`, game);
  }

  exportGame(gameId: string) {
    return this.apiService.getBase64(`/games/${gameId}/json`);
  }

  deleteGame(gameId: string) {
    return this.apiService.delete(`/games/${gameId}`);
  }

  startGameSession(gameSessionId: string) {
    return this.apiService.patch(`/game-sessions/${gameSessionId}/start`);
  }

  getUserGames(userId: string, searchString: string): Observable<Game[]> {
    return this.http.get<Game[]>(
      // `${this.urlApi}/games/users/${userId}?search=${searchString}`
      `${this.urlApi}/games/available-to-me?search=${searchString}`
    );
  }

  getAdminGames(userId: string, searchString: string): Observable<Game[]> {
    return this.http.get<Game[]>(
      // `${this.urlApi}/games/users/${userId}?search=${searchString}`
      `${this.urlApi}/games/available-to-me-as-admin?search=${searchString}`
    );
  }

  searchGame(searchConfig?: searchConfig): Observable<Game[]> {
    return this.apiService.search(routes.games, searchConfig ?? {});
  }

  createGame(game: any) {
    return this.apiService.post('/games', game);
  }

  createGameFromJson(orgId: string, file: any) {
    return this.apiService.postImage(routes.gamesFromJson(orgId), file);
  }

  getChapters(id: string): Observable<any> {
    return this.apiService.get(`/games/${id}/chapters`);
  }

  getUserStats(
    userId: string,
    gameId: string,
    instanceId: string,
    range: string
  ) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/participants/stats/users/${userId}?range=${range}`
    );
  }

  getGameRanking(gameId: string, instanceId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/participants/ranking`
    );
  }

  getUserAdvancement(gameId: string) {
    return this.apiService.get(`/games/${gameId}/advancement`);
  }

  getUserStartedGames(userId: string) {
    return this.apiService.get(`/games/available-to-me/already-started`);
  }

  /* ------------------ Participants ------------------ */

  // getParticipants(gameId: string) {
  //   return this.apiService.get(routes.gameParticipants(gameId));
  // }

  // inviteParticipantsWithEmail(gameId: string, emailList: any) {
  //   return this.apiService.post(routes.gameParticipants(gameId), emailList);
  // }

  /* ------------------ Managers ------------------ */

  getManagers(gameId: string) {
    return this.apiService.get(routes.gameManagers(gameId));
  }

  addManagers(gameId: string, managerList: any) {
    return this.apiService.post(routes.gameManagers(gameId), managerList);
  }

  removeManagers(id: string, managerList: any): Observable<any> {
    return this.apiService.deleteWithBody(routes.gameManagers(id), managerList);
  }

  /* ------------------ Images ------------------ */

  uploadGameMascotImage(id: string, file: any): Observable<any> {
    return this.apiService.postImage(routes.gameMascotImg(id), file);
  }

  uploadGameBackgroundImage(id: string, file: any): Observable<any> {
    return this.apiService.postImage(routes.gameBackgroundImg(id), file);
  }

  uploadGameCardBackgroundImage(id: string, file: any): Observable<any> {
    return this.apiService.postImage(routes.gameCardBackgroundImg(id), file);
  }

  uploadStoryBackgroundImage(id: string, file: any): Observable<any> {
    return this.apiService.postImage(routes.gameBackgroundStoryPage(id), file);
  }

  uploadProgressBackgroundImage(id: string, file: any): Observable<any> {
    return this.apiService.postImage(
      routes.gameBackgroundProgressPage(id),
      file
    );
  }

  uploadRankingBackgroundImage(id: string, file: any): Observable<any> {
    return this.apiService.postImage(
      routes.gameBackgroundRankingPage(id),
      file
    );
  }

  getPdfFile(url: string) {
    return this.apiService.getBase64(`${url.split('api')[1]}`);
  }

  /* ------------------ Level Icons ------------------ */

  uploadProfilingLevelIcon(id: string, file: any): Observable<any> {
    return this.apiService.postImage(routes.gameProfilingIcon(id), file);
  }

  uploadQuestionLevelIcon(id: string, file: any): Observable<any> {
    return this.apiService.postImage(routes.gameQuestionIcon(id), file);
  }

  uploadStoryLevelIcon(id: string, file: any): Observable<any> {
    return this.apiService.postImage(routes.gameStoryIcon(id), file);
  }

  uploadLockedLevelIcon(id: string, file: any): Observable<any> {
    return this.apiService.postImage(routes.gameLockedIcon(id), file);
  }

  /* ------------------ Instances ------------------ */

  createGameInstance(gameId: string, instance: any) {
    return this.apiService.post(`/games/${gameId}/instances`, instance);
  }

  getAllGameInstances(gameId: string) {
    return this.apiService.get(routes.gameInstances(gameId));
  }

  searchGameInstances(gameId: string, search?: searchConfig): Observable<any> {
    return this.apiService
      .get(
        `${routes.gameInstances(gameId)}${search ? '?search=' + search : ''}`
      )
      .pipe(
        map((data) => {
          return data['data'];
        })
      );
  }

  updateGameInstance(gameId: string, instance: any) {
    return this.apiService.patch(
      `/games/${gameId}/instances/${instance.id}`,
      instance
    );
  }

  getGameAdminInstance(gameId: string) {
    return this.apiService.get(`/games/${gameId}/admin-instance`);
  }

  getGameInstance(gameId: string, instanceId: string) {
    return this.apiService.get(`/games/${gameId}/instances/${instanceId}`);
  }

  /* ------------------ Easter Eggs ------------------ */

  mascotTickle(gameId: string, instanceId: string) {
    return this.apiService.post(
      `/games/${gameId}/instances/${instanceId}/mascot-tickle`
    );
  }

  /* ------------------ Psycho ------------------ */

  createOrUpdateResultPhrase(gameId: string, body: any) {
    return this.apiService.post(routes.gameResultPhrase(gameId), body);
  }

  /* ------------------ Stats ------------------ */

  getGameLevelPlayedByDate(
    gameId: string,
    startDate: string,
    endDate: string,
    instances?: string[]
  ) {
    let call = `/games/${gameId}/level-sessions-between-dates`;

    if (startDate) {
      call += `?start-date=${startDate}`;
    }

    if (endDate) {
      call += `&end-date=${endDate}`;
    }

    if (instances) {
      for (let i = 0; i < instances.length; i++) {
        call += `&instance=${instances[i]}`;
      }
    }
    return this.apiService.get(call);
  }

  getGameLastLevelUnlocked(gameId: string, instanceId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/players-doing-last-unlocked-unit`
    );
  }

  getPlayerXpPerDay(gameId: string, instanceId: string, userId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/participants/xp-per-day/users/${userId}`
    );
  }

  getPlayerPercentageRightAnswers(
    gameId: string,
    instanceId: string,
    userId: string
  ) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/participants/percentage-right-anwers-per-day/users/${userId}`
    );
  }

  getTimeSpentPerLevel(gameId: string, instanceId: string, userId: string) {
    return this.apiService.get(
      `/games/${gameId}/instances/${instanceId}/participants/time-spent-in-levels-per-day/users/${userId}`
    );
  }

  getGameInstancesAsAdmin(searchString: string) {
    return this.http.get<Game[]>(
      // `${this.urlApi}/games/users/${userId}?search=${searchString}`
      `${this.urlApi}/games/instances/available-to-me-as-admin?search=${searchString}`
    );
  }
}
