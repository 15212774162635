<div class="flex justify-between items-center gap-5 dialog-title">
  <h4 class="mb-0">
    {{
      data.config && data.config.header.label
        ? data.config.header.label
        : "Modifier une offre"
    }}
  </h4>

  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon class="dark:text-white">close</mat-icon>
  </button>
</div>

<mat-dialog-content
  class="mat-typography flex flex-col justify-start items-stretch gap-5"
>
  @if (data.hasImport) {
  <mat-tab-group
    (selectedTabChange)="onTabChange($event)"
    [selectedIndex]="selectedTabIndex"
  >
    <mat-tab
      label="{{ data.config.tabLabels.firstTab }}"
      [disabled]="fieldsForm.length === 0"
    >
      <form
        class="mt-4"
        [formGroup]="dialogForm"
        (ngSubmit)="submitDialogForm()"
      >
        <formly-form
          [model]="dialogModel"
          [fields]="fieldsForm"
          [options]="options"
          [form]="dialogForm"
          [ngClass]="cssClasses"
        ></formly-form>
      </form>
    </mat-tab>
    <mat-tab
      label="{{ data.config.tabLabels.secondTab }}"
      [disabled]="importFieldsForm.length === 0"
    >
      <div class="flex flex-row gap-2.5 items-stretch">
        <div class="mt-4" style="max-width: 50%">
          <h5>{{ importInstructionsTitle }}</h5>
          <mat-divider></mat-divider>

          <div class="my-4">
            <span>{{ importInstruction }}</span>
            <div class="code max-h-96 overflow-auto">
              @if(importInstructionsContent) {
              <ul>
                @for (instruction of importInstructionsContent; track $index) {
                <li>{{ instruction }}</li>
                }
              </ul>
              } @if(importInstructionsPreContent) {
              <pre><code>{{ importInstructionsPreContent | json }}</code></pre>
              }
            </div>
          </div>
        </div>

        <mat-divider [vertical]="true" class="mt-4"></mat-divider>

        <form
          class="mt-4 basis-2/3 flex flex-col justify-center items-stretch"
          [formGroup]="importDialogForm"
          (ngSubmit)="submitDialogForm()"
        >
          <formly-form
            [model]="importDialogModel"
            [fields]="importFieldsForm"
            [options]="options"
            [form]="importDialogForm"
            [ngClass]="cssClasses"
          ></formly-form>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div
    class="flex justify-end items-center gap-5 dialog-actions"
    *ngIf="data.config && data.config.actions"
  >
    <button mat-stroked-button mat-dialog-close [mat-dialog-close]="true">
      {{ data.config.actions.cancel ? data.config.actions.cancel : "Annuler" }}
    </button>
    <!-- <button mat-flat-button color="primary" (click)="submitDialogForm()" [disabled]="!importDialogForm.valid || selectedTabIndex != 1"> -->
    <button
      mat-flat-button
      color="primary"
      (click)="submitDialogForm()"
      [disabled]="
        selectedTabIndex == 1 ? !importDialogForm.valid : !dialogForm.valid
      "
    >
      {{ data.config.actions.submit ? data.config.actions.submit : "Valider" }}
    </button>
  </div>

  } @else {
  <form [formGroup]="dialogForm" (ngSubmit)="submitDialogForm()">
    <formly-form
      [model]="dialogModel"
      [fields]="fieldsForm"
      [options]="options"
      [form]="dialogForm"
      [ngClass]="cssClasses"
    ></formly-form>
  </form>

  <div
    class="flex justify-end items-center gap-5 dialog-actions"
    *ngIf="data.config && data.config.actions"
  >
    <button mat-stroked-button mat-dialog-close [mat-dialog-close]="true">
      {{ data.config.actions.cancel ? data.config.actions.cancel : "Annuler" }}
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="submitDialogForm()"
      [disabled]="!dialogForm.valid"
    >
      {{ data.config.actions.submit ? data.config.actions.submit : "Valider" }}
    </button>
  </div>
  }
</mat-dialog-content>
