import { ChangeDetectorRef, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-fyyyre-matching',
  templateUrl: './fyyyre-matching.component.html',
  styleUrl: './fyyyre-matching.component.scss',
})

export class FyyyreMatchingComponent extends FieldType<FieldTypeConfig> {
  fromOptionsList: any = [];
  toOptionsList: any = [];
  randomToIndexes: any = [];
  selectedIndex = 0;

  showExplanation: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    let options: any = this.props.options;

    // Add answerState form answer state management ( good / bad / disable )
    this.fromOptionsList = options.from;
    this.fromOptionsList.map((x: any) => x['answerState'] = 'undefined');

    // Add answerState form answer state management ( good / bad / disable )
    this.toOptionsList = options.to;
    this.toOptionsList.map((x: any) => x['answerState'] = 'undefined');

    this.showExplanation = this.props['showExplanation'];

    while (this.randomToIndexes.length < this.toOptionsList.length) {
      const randomIndex = Math.floor(Math.random() * this.toOptionsList.length);
      if (this.randomToIndexes.indexOf(randomIndex) === -1) {
        this.randomToIndexes.push(randomIndex);
      }
    }
  }

  formIsSubmit() {
    return this.options.formState['submit'];
  }

  updateSelectedIndex(option: any, index: any) {
    // if (option.from.answerState == 'undefined' && !this.formIsSubmit()) {
    //   this.selectedIndex = index;
    // }
    if (option.answerState == 'undefined' && !this.formIsSubmit()) {
      this.selectedIndex = index;
    }
  }

  /**
   * Checks user response data
   * @param option toOptionsList
   * @param index
   */
  verifAnswer(option: any, index: number) {
    if (option.answerState != ('good' && 'disable') && !this.formIsSubmit()) {
      // If Good Answer
      if (this.props['expectedAnswers'][this.selectedIndex].to == option.value) {

        this.fromOptionsList[this.selectedIndex].answerState = 'good'; // Update From List ( Left List )
        option.answerState = 'good'; // Update To List ( Right List )

        // After 0.5 sec change state option 'good' -> 'disable' for allow background to pass from green to grey
        setTimeout(() => {
          option.answerState = 'disable';
          this.fromOptionsList[this.selectedIndex].answerState = 'disable';

          // Update Form Value
          if (this.field.formControl.value) {
            this.field.formControl?.setValue(this.field.formControl.value.concat(this.props['expectedAnswers'][this.selectedIndex]));
          } else {
            this.field.formControl?.setValue([this.props['expectedAnswers'][this.selectedIndex]]);
          }

          // Asked by Yann (12/11/24) : disable next button if all answers are not selected
          if (this.field.formControl.value.length !== this.fromOptionsList.length) {
            this.field.formControl?.setErrors({ required: true });
          }

          this.selectedIndex++;
          this.cdr.detectChanges(); // Force change detection
        }, 500);

      } else {
        // Else is Bad Answer
        this.fromOptionsList[this.selectedIndex].answerState = 'bad'; // Update From List ( Left List )
        option.answerState = 'bad';// Update To List ( Right List )

        // After 0.5 sec change state option 'bad' -> 'default' for allow background to pass from red to default
        setTimeout(() => {
          option.answerState = 'undefined';
          this.fromOptionsList[this.selectedIndex].answerState = 'undefined'
          this.cdr.detectChanges(); // Force change detection
        }, 500);

      }
    }
  }

  // Management for css custom style
  dynamicCssStyle(index: number, type: any) {
    let cssStyle: any = {};

    // Set color to option when selection done
    if (this.selectedIndex == index && type == "from") {
      cssStyle = { 'color': '#00c9a2', 'background-color': '#00c9a226' } // Accent color
    }

    return cssStyle;

  }

}
