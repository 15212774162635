<div class="flex flex-col gap-1 w-full">
  <h5>{{ to["label"] }}</h5>

  @if(to["explanation"] && formIsSubmit() && !to["showExplanation"]){
  <mat-slide-toggle [checked]="showExplanation" (change)="showExplanation = !showExplanation" color="primary"
    [class]="showExplanation? 'mt-3' : 'my-3'">
    <b class="dark:text-white">Afficher l'explication </b>
  </mat-slide-toggle>
  }
  @if(to["explanation"] && formIsSubmit() && (to["showExplanation"] || showExplanation)){
  <mat-card class="mat-elevation-z0 py-1 px-2 my-3 glass-card">
    <mat-card-content class="flex flex-col gap-2">
      <div class="flex gap-3 items-center">
        <mat-icon svgIcon="question-mark-circle-light" color="accent"></mat-icon>
        <h5 class="mb-0">Explication des résultats :</h5>
      </div>
      <mat-divider></mat-divider>

      <p class="  text-black dark:text-white font-light">
        {{ to["explanation"] }}
      </p>
    </mat-card-content>
  </mat-card>
  }

  <div cdkDropList class="example-list w-full  px-2" (cdkDropListDropped)="drop($event)">
    @for (option of optionList; track option;let i = $index) {
    <mat-card class="example-box mat-elevation-z0 py-1 px-2 my-3  glass-card" cdkDrag [ngClass]="showAnswers(i, option)"
      [cdkDragDisabled]="disableSorting">
      <mat-card-content class="flex flex-col sm:flex-row items-center">
        <div class="example-custom-placeholder flex items-center" *cdkDragPlaceholder>
          <div class="border-2 rounded-lg w-full "></div>
        </div>
        {{ "#" + (i + 1) }}
        <span class="mx-3 invisible sm:visible">|</span>
        <span class="user-value mr-1"> {{ option.label | titlecase }}</span>
        <span class="rotate-90 sm:rotate-0 sm:mx-3 "> {{ option.answer ?' → ' :'' }}</span>
        <span> {{ option.answer ?? "" | titlecase }}</span>
      </mat-card-content>
    </mat-card>
    }
  </div>

</div>