import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateOrEditDialogComponent } from '../../components/create-or-edit-dialog/create-or-edit-dialog.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string,
      message: string,
      isDeleteConfirmation?: boolean,
    },
    public dialogRef: MatDialogRef<CreateOrEditDialogComponent>
  ) { }
}
