<div class="flex justify-between items-center gap-5 dialog-title">
  <h4 class="mb-0">
    {{
    data.title ?? "Confirmation nécessaire"
    }}
  </h4>

  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon class="dark:text-white">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography flex flex-col justify-start items-stretch gap-5">

  <span class="text-slate-500">
    {{data.message}}
  </span>

  <div class="flex justify-end items-center gap-5 dialog-actions">
    <button mat-stroked-button mat-dialog-close [mat-dialog-close]="false">
      Annuler
    </button>
    <button mat-flat-button [color]='data.isDeleteConfirmation ? "warn" : "primary"' [mat-dialog-close]="true">
      Confirmer
    </button>
  </div>
</mat-dialog-content>
