import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@env';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { searchConfig } from '../models/search.config.model';

const BASE_URL = env.apiURL;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private options = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
    withCredentials: true,
  };

  constructor(private httpClient: HttpClient) { }

  public get(
    path: string,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    return this.httpClient
      .get(BASE_URL + path, { params, withCredentials: true })
      .pipe(catchError(this.formatErrors));
  }

  public getBase64(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(BASE_URL + path, { responseType: 'blob', withCredentials: true }).pipe(catchError(this.formatErrors));
  }

  public search(
    path: string,
    searchConfig?: searchConfig,
    params: HttpParams = new HttpParams()
  ): Observable<any> {
    let pathModified = false;
    const { search, filter, sort, limit, page } = searchConfig as any;
    // Add Filter
    if (filter) {
      path += (pathModified ? '&' : '?') + `filter.${filter}`;
      pathModified = true;
    }
    // Add Search
    if (search) {
      path += (pathModified ? '&' : '?') + 'search=' + (search ? search : '');
      pathModified = true;
    }
    return this.httpClient
      .get(BASE_URL + path, { params, withCredentials: true })
      .pipe(catchError(this.formatErrors));
  }

  public put(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .put(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public patch(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .patch(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .post(BASE_URL + path, JSON.stringify(body), this.options)
      .pipe(catchError(this.formatErrors));
  }

  public postImage(path: string, body: object = {}): Observable<any> {
    return this.httpClient
      .post(BASE_URL + path, body, { withCredentials: true })
      .pipe(catchError(this.formatErrors));
  }

  public delete(path: string): Observable<any> {
    return this.httpClient
      .delete(BASE_URL + path, { withCredentials: true })
      .pipe(catchError(this.formatErrors));
  }
  public deleteWithBody(path: string, body: any): Observable<any> {
    return this.httpClient
      .delete(BASE_URL + path, { withCredentials: true, body: body })
      .pipe(catchError(this.formatErrors));
  }

  public formatErrors(error: any): Observable<any> {
    return throwError(error.error);
  }
}
