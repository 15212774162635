import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  endpoint: string = environment.apiURL;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) { }


  /**
 * Global Getter
 * @param path API route
 * @returns Observable
 */
  getAPIGlobal(path: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${path}`);
  }

  getApiGlobalWithSingleFilter(path: string, filter: any): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${path}?filter.${filter}`);
  }
  getApiGlobalWithFilters(path: string, filter: any, search?: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${path}?filter.${filter}${'&search=' + (search ? search : "")}`);
  }



  /**
* Global Getter
* @param path API route
* @returns Observable
*/
  getAPIGlobalPaginated(path: string, page: number, limit?: number, search?: string, sort?: string, filter?: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/${path}${path.includes('?filter.') ? '&' : '?'}${'page=' + (page + 1) + "&"}${'search=' + (search ? search : "")}${'&limit=' + (limit ? limit : 20)}${'&sortBy=' + sort}&filter.${filter}`);
  }

  /**
 * Global Create
 * @param path API route
 * @param item Item to create
 * @returns Observable
 */
  createAPIGlobal(path: string, item: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${path}`, item);
  }

  /**
* Global Update
* @param path API route
* @param item Item to update
* @param id ID of item
* @returns Observable
*/
  updateAPIGlobal(path: string, id: any, item: any): Observable<any> {
    return this.http.patch<any>(`${this.endpoint}/${path}/${id}`, item);
  }

  /**
   * Global Delete
   * @param path API route
   * @param id ID of item to delete
   * @returns Observable
   */
  deleteAPIGlobal(path: string, id: any): Observable<any> {
    return this.http.delete<any>(`${this.endpoint}/${path}/${id}`);
  }
  /**
 * Global Delete
 * @param path API route
 * @param id ID of item to delete
 * @returns Observable
 */
  deleteMultipleAPIGlobal(path: string, obj: any): Observable<any> {
    return this.http.delete<any>(`${this.endpoint}/${path}`, { body: JSON.stringify(obj) });
  }


  /**
   *
   * @param path API route
   * @param id ID of entity
   * @param file File to upload
   * @returns
   */
  createImageForEntity(path: string, id: any, file: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${path}/${id}`, file);
  }

  /**
 *
 * @param path API route
 * @param id ID of entity
 * @param file File to upload
 * @returns
 */
  updateAvatar(path: string, id: any, file: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/${path}/avatar/${id}`, file);
  }

  /**
  * Triggers the display of a pop up message
  * @param message Message display ex. 'Hello world !'
  * @param action  Message display in close btn
  */
  openSnackBar(message: string, action: string, duration: number = 3) {
    this._snackBar.open(message, action, {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: duration * 1000,
      panelClass: ['snackbar-theme'],
    });
  }

  lang = "fr";
  displayErrorMessage(error: any) {

    if (error.error) {
      error = error.error;
    }

    var error_message = "";

    // If Current Language isn't English -> Traduction
    if (this.lang !== "en") {
      error_message = this.erroMessageTraduction[error.statusCode][this.lang] ? this.erroMessageTraduction[error.statusCode][this.lang] : error.statusCode + " - untranslated error";
    } else {
      // Else if english -> return error message
      error_message += " - " + error.message.toString()
    }


    return error_message;
  }


  erroMessageTraduction: any = {
    // Bad Request
    400: {
      fr: 'Mauvaise requête'
    },
    // Unauthorized
    401: {
      fr: 'Accès non autorisé'
    },
    // Forbidden
    403: {
      fr: 'Accès interdit'
    },
    // Not Found
    404: {
      fr: 'Non trouvé'
    },
    // Item already existing
    409: {
      fr: 'Ce dispositif est déjà enregistré sur ce site'
    },
    // Internal Server Error
    500: {
      fr: 'Erreur de serveur interne'
    }
  }

}


