import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, afterRender, inject, signal } from '@angular/core';
import { themeFromSourceColor, argbFromHex, TonalPalette, hexFromArgb } from '@material/material-color-utilities';
import { copyFileSync } from 'node:fs';
import { BehaviorSubject } from 'rxjs';
import tinycolor from 'tinycolor2';
export const DEFAULT_COLOR = '#8714fa';

type WithStylesheet = typeof globalThis & {
  [stylesheetName: string]: CSSStyleSheet | undefined;
};

type colorsFromPaletteConfig = {
  primary: { hex: string; tone: number; darkContrast: boolean }[];
  accent: { hex: string; tone: number; darkContrast: boolean }[];
  warn: { hex: string; tone: number; darkContrast: boolean }[];
};



@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _renderer!: Renderer2;
  private _theme = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly _rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) public document: Document
  ) {
    afterRender(() => {
      if (!this.storageMode) {
        this.storageMode = false;
      }
      this._renderer = this._rendererFactory.createRenderer(null, null);

      if (localStorage.getItem('tmpTheme')) {
        this.setMode(localStorage.getItem('tmpTheme'));
      } else {
        this.setMode(this.storageMode !== 'false');
      }

    });
  }

  setMode(value: any): void {
    this.storageMode = value;
    this._theme.next(value);
    if (this._renderer) {
      this._renderer.removeClass(this.document.body, 'dark');
      this._renderer.removeClass(this.document.body, 'light');
      this._renderer.addClass(this.document.body, this._modeClass);
    }

  }

  setTmpMode(value: any): void {

    if (localStorage.getItem('oldTheme') === null) {
      localStorage.setItem('oldTheme', this.storageMode);
    }

    this.setMode(value);

  }

  resetThemeToDefault() {
    localStorage.removeItem('tmpTheme');
    const oldTheme = localStorage.getItem('oldTheme');
    if (oldTheme !== null && oldTheme !== '' && oldTheme !== undefined) {
      localStorage.removeItem('oldTheme');
      this.setMode(oldTheme);
    }
  }

  get mode(): boolean {
    return this._theme.value;
  }

  get storageMode(): any {
    return localStorage.getItem('darkTheme');
  }

  set storageMode(value: any) {
    localStorage.setItem('darkTheme', value);
  }

  private get _modeClass(): string {
    return this._theme.value ? 'dark' : 'light';
  }


  /* ------------------------ Dynamic Theming ------------------------ */
  #document = inject(DOCUMENT);
  themeLoaded = false;
  gameColors: any = {
    primary: '#3b2a7f',
    secondary: '#00c9a2',
    tertiary: '#c34e96',
  }

  defaultAppColors: any = {
    primary: '#3b2a7f',
    secondary: '#00c9a2',
    tertiary: '#c34e96'
  }
  themeIsLoaded() {
    return this.themeLoaded;
  }

  getPrimaryColor() {
    return this.gameColors.primary;
  }

  getAccentColor() {
    return this.gameColors.secondary;
  }

  getWarnColor() {
    return this.gameColors.tertiary;
  }

  resetAppColors() {
    this.gameColors = this.defaultAppColors;
    this.themeFromSelectedColor(this.gameColors.secondary, 'secondary');
  }

  loadGameTheme(themeColors: any) {
    this.themeLoaded = false;
    this.gameColors = themeColors;
    this.themeFromSelectedColor(this.gameColors.secondary, 'secondary');
    this.themeLoaded = true;
  }

  onColorChange(event: any) {
    this.themeFromSelectedColor(event.value, event.type);
  }

  themeFromSelectedColor(color: any, type?: 'primary' | 'secondary' | 'tertiary'): void {

    const tones = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 'A100', 'A200', 'A400', 'A700'];
    const theme = themeFromSourceColor(argbFromHex(this.gameColors.primary ?? DEFAULT_COLOR));

    const colors = Object.entries(theme.palettes).reduce(
      (acc: any, curr: [string, TonalPalette]) => {

        const hexColors = tones.map((tone) => ({
          tone,
          hex: curr[0] == type ? color : this.gameColors[curr[0]], // hexFromArgb(curr[1].tone(typeof (tone) == "string" ? Number(tone) / 10 : tone / 10)),
          darkContrast: tinycolor(this.gameColors[curr[0]]).isLight(),
        }));

        if (curr[0] == type) {
          this.gameColors[curr[0]] = color;
        }

        return { ...acc, [curr[0]]: hexColors };
      },
      {}
    );

    this.createCustomProperties(colors, type);
  }

  createCustomProperties(
    colorsFromPaletteConfig: colorsFromPaletteConfig,
    paletteKey: 'primary' | 'secondary' | 'tertiary' | undefined,
    doc?: DocumentOrShadowRoot
  ) {
    let styleString = ':root,:host{';
    for (const [key, palette] of Object.entries(colorsFromPaletteConfig)) {
      palette.forEach(({ hex, tone, darkContrast }) => {
        styleString += `--${key == 'secondary' ? 'accent' : key}-${tone}:${hex};`;
        styleString += `--${key == 'secondary' ? 'accent' : key}-contrast-${tone}:${darkContrast ? 'rgba(black, 0.87)' : 'white'};`;
      });
    }
    styleString += '}';
    this.applyThemeString(styleString, 'angular-material-theme');
  }

  applyThemeString(themeString: string, ssName = 'angular-material-theme') {
    let sheet = (globalThis as WithStylesheet)[ssName];
    if (!sheet) {
      sheet = new CSSStyleSheet();
      (globalThis as WithStylesheet)[ssName] = sheet;
      this.#document.adoptedStyleSheets.push(sheet);
    }
    sheet.replaceSync(themeString);
  }

  /* ------------------------ / Dynamic Theming ------------------------ */

}
