<form [formGroup]="questionForm" (ngSubmit)="submitQuestion()" class="w-100">
  <formly-form [model]="{}" [fields]="questionFormlyField" [form]="questionForm" [options]="options"
    [ngClass]="{ submitted: countSubmitBtn > 1 }"></formly-form>
  <div class="flex flex-col md:flex-row justify-end items-center gap-3 mt-3 w-full">

    @if(countSubmitBtn==0){
    <div matTooltip="Sélectionnez au moins une réponse pour passer à la question suivante !"
      [matTooltipDisabled]="questionForm.valid">

      <button mat-flat-button color="primary" type="submit" [disabled]="!questionForm.valid"
        class="w-full md:w-fit text-black dark:text-white" #submitBtn>
        Valider ma réponse
      </button>
    </div>
    } @else {
    <button mat-flat-button color="primary" type="submit" class="w-full md:w-fit text-black dark:text-white">
      {{ isLastStep ? "Terminer" : "Question suivante" }}
    </button>
    }

  </div>
</form>