import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Component({
  selector: 'app-create-or-edit-dialog',
  templateUrl: './create-or-edit-dialog.component.html',
  styleUrls: ['./create-or-edit-dialog.component.scss'],
})
export class CreateOrEditDialogComponent {
  dialogForm = new FormGroup({});
  dialogModel: any = {};

  importDialogForm = new FormGroup({});
  importDialogModel: any = {};
  importInstructionsTitle: string = '';
  importInstructionsContent?: any[];
  importInstructionsPreContent?: string;
  importInstruction: string = '';

  selectedTabIndex: number = 0;

  isFirstTab: boolean = true;
  isSecondTab: boolean = false;

  fieldGroup: any = [];

  options: FormlyFormOptions = {
    formState: {
      cancel: false,
    },
  };
  fieldsForm: FormlyFieldConfig[] = [];
  importFieldsForm: FormlyFieldConfig[] = [];

  public cssClasses: Array<string> = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateOrEditDialogComponent>
  ) {}

  ngOnInit(): void {
    this.cssClasses = [];

    // For form
    if (this.data['form']) {
      this.dialogForm = this.data['form'];
    }

    if (this.data['field']) {
      let field = this.data['field'];
      field.push({ cancel: false });
      this.fieldsForm = field;
    }

    if (this.data['model']) {
      this.dialogModel = this.data['model'];
    }

    // For import form

    if (this.data['importForm']) {
      this.importDialogForm = this.data['importForm'];
    }

    if (this.data['importField']) {
      let field = this.data['importField'];
      field.push({ cancel: false });
      this.importFieldsForm = field;
    }

    if (this.data['importModel']) {
      this.importDialogModel = this.data['importModel'];
    }

    if (this.data['importInstructions']) {
      this.importInstructionsTitle = this.data['importInstructions'].title;
      this.importInstruction = this.data['importInstructions'].instruction;
      this.importInstructionsContent = this.data['importInstructions'].content;
      this.importInstructionsPreContent =
        this.data['importInstructions'].preContent;
    }

    if (this.data['onImportPage']) {
      this.selectedTabIndex = 1;
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
  }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.

    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.data.config && this.data.config.style) {
      this.getAdditionalCssClasses(this.data.config.style);
    }
  }

  submitDialogForm() {
    if (this.options.formState.cancel) {
      this.dialogModel = undefined;
      this.importDialogModel = undefined;
    }

    if (this.selectedTabIndex != 0) {
      this.dialogRef.close(this.importDialogModel);
    } else {
      this.dialogRef.close(this.dialogModel);
    }
  }

  getAdditionalCssClasses(configCSS: any) {
    if (configCSS.showBorder) {
      this.cssClasses.push('formly-field-border');
    }
  }

  getIconModal() {
    if (this.data.config && this.data.config.header.icon) {
      return this.data.config.header.icon;
    } else return 'ticket-bulk';
  }
}
