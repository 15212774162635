<div class="flex justify-between items-center gap-5 dialog-title">
  <h4 class="mb-0">
    {{ data.title }}
  </h4>

  <!-- <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon class="dark:text-white">close</mat-icon>
  </button> -->
</div>

<mat-dialog-content
  class="mat-typography flex flex-col justify-start items-stretch gap-5"
>
  <div class="flex flex-row items-center gap-5">
    <div class="loader"></div>
    <p>{{ data.content }}</p>
  </div>
</mat-dialog-content>
