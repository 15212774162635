import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-fyyyre-fill-blank',
  templateUrl: './fyyyre-fill-blank.component.html',
  styleUrl: './fyyyre-fill-blank.component.scss',
})
export class FyyyreFillBlankComponent extends FieldType<FieldTypeConfig> {
  textWithGaps: string = '';
  initWords: any[] = [];
  avaibleWords: any[] = []; // Not used words
  usedWords: any[] = []; // Already used words
  disableForm = false;

  showExplanation: boolean = false;

  questionDetails: any = {
    answers: [],
    options: [],
    questions: []
  }

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();

    this.showExplanation = this.props['showExplanation'];
  }
  showGame = false;
  initializeForm() {
    // Split Text
    let options: any = this.props.options;
    this.initWords = options.values;
    this.avaibleWords = options.values;
    this.usedWords = new Array(options.values.length).fill(null);
    let text = options.text.split(/\{\d+\}/);

    this.questionDetails.questions = text//.map((sentence:any)=> [sentence])
    //this.questionDetails.answers = new Array(options.values.length - 1).fill([]);
    this.questionDetails.answers = JSON.parse(JSON.stringify(new Array(options.values.length).fill([])))
    this.questionDetails.options = options.values.slice();

    this.textWithGaps = text.join('<span class="gap-text">_ _ _</span>');
    // Forcer la détection des changements
    this.cdr.detectChanges();
  }

  insertWord(option: any) {
    if (!this.formIsSubmit()) {
      // Find first element with value null
      const indexFirstEmptyValue = this.usedWords.findIndex(
        (word: any) => word == null
      );

      if (indexFirstEmptyValue != -1) {
        this.usedWords[indexFirstEmptyValue] = option; // Add the selected word to the list of used words
      }
      this.avaibleWords = this.initWords.filter(
        (i: any) => !this.usedWords.includes(i)
      );

      // Replace the next gap with the selected word
      this.textWithGaps = this.textWithGaps.replace(
        '<span class="gap-text">_ _ _</span>',
        `<span class="gap-text mat-accent-color px-1">${option.label}</span>`
      );

      // Update Form with new Value
      this.field.formControl?.setValue(
        this.usedWords.map((o: any) => (o ? (o = { value: o.value }) : 'null'))
      );

      // Handle Click Event
      setTimeout(() => {
        this.bindDeleteClickEvent(); // Lie l'événement de clic à chaque nouvel élément avec la classe gap-text
      }, 100);
    }
  }


  /* ---------------- DOM Management ---------------- */

  bindDeleteClickEvent() {
    const insertedTextElements = this.elementRef.nativeElement.querySelectorAll('.gap-text');
    insertedTextElements.forEach((element: any) => {
      element.addEventListener('click', this.deleteTextOnClick.bind(this));
    });
  }

  deleteTextOnClick(event: Event) {
    if (!this.formIsSubmit()) {
      let clickedElement = event.target as HTMLElement;
      const indexElementToDelete = this.usedWords.findIndex(
        (element: any) =>
          (element ? element.label : '') == clickedElement.innerHTML
      );

      this.usedWords[indexElementToDelete] = null;
      this.avaibleWords = this.initWords.filter(
        (i: any) => !this.usedWords.includes(i)
      );

      // Reset Element to Original Value
      this.textWithGaps = this.textWithGaps.replace(
        clickedElement.outerHTML as string,
        '<span class="gap-text">_ _ _</span>'
      );

      // Reset original gap
      (
        this.elementRef.nativeElement.querySelector('#question-text') as HTMLElement
      ).innerHTML = this.textWithGaps; // Update DOM

      // Handle Click Event on new DOM
      setTimeout(() => {
        this.bindDeleteClickEvent(); // Lie l'événement de clic à chaque nouvel élément avec la classe gap-text
      }, 500);

      // Forcer la détection des changements
      this.cdr.detectChanges();
    }
  }

  /* ---------------- / DOM Management ---------------- */
  getClassName() {
    return this.field.className;
  }

  formIsSubmit() {
    return this.options.formState['submit'];
  }

  showAnswers() {
    if (this.formIsSubmit()) {
      this.disableForm = true;
      // Update Css Class with ( Good / Bad Answers)
      const insertedTextElements =
        this.elementRef.nativeElement.querySelectorAll('.gap-text');
      insertedTextElements.forEach((element: any, index: any) => {
        let answer: HTMLElement = element as HTMLElement;
        if (this.props['expectedAnswers'][index].value == this.field.formControl.value[index].value) {
          answer.className += ' good-answer';
          answer.innerHTML = `<span class="user-value">${answer.innerHTML}</span>`;
        } else {
          const correctAnswer = this.initWords.find(
            (option) =>
              option.value === this.props['expectedAnswers'][index].value
          );
          answer.className += ' bad-answer';
          answer.innerHTML = `<span class="user-value">${answer.innerHTML}</span>` + `<span> → ${correctAnswer.label} </span>`
        }
      });
    }
    return [];
  }

  getLabel(value: any) {
    return value ? value.label : " "
  }

  public trackByMethod(index: number): number {
    return index;
  }

  drop(event: CdkDragDrop<string[]>, type: string) {

    if (event.previousContainer === event.container) {
      return;
    }

    const allowTheMove = () => {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      // Update Form Control Value
      this.field.formControl?.setValue(
        this.questionDetails.answers.map((answer: any) => answer[0] ? (answer = { value: answer[0].value }) : 'null').filter((item: any) => item !== 'null')
      );

    };

    const swap = () => {
      let arr_from: any = type === "source" ? event.container.data : event.previousContainer.data;
      let arr_to = type === "source" ? event.previousContainer.data : event.container.data;
      const oldAnswer: any = arr_from[0];

      if (type === 'answer') {
        arr_from[0] = {};
        arr_to.push(oldAnswer);
      }

      if (type === 'source') {
        arr_from[0] = arr_to[event.previousIndex]
        if (oldAnswer.label) {
          arr_to[event.previousIndex] = oldAnswer
        } else {
          arr_to.splice(event.previousIndex, 1)
        }
      }

      // Update Form Control Value
      this.field.formControl?.setValue(
        this.questionDetails.answers.map((answer: any) => answer[0] ? (answer = { value: answer[0].value }) : 'null').filter((item: any) => item !== 'null')
      );

    };

    if (event.container.data.length === 0) {
      allowTheMove();
    } else {
      swap();
    }

  }

  removeElement(index: any) {
    if (!this.formIsSubmit()) {
      const tmp = this.questionDetails.answers[index][0];
      this.questionDetails.answers[index] = [];
      this.questionDetails.options.unshift(tmp)
    }
  }

  showAnswersV2(index: any) {
    if (this.formIsSubmit()) {
      this.disableForm = true;

      // Good Answer
      if (
        this.questionDetails.answers[index][0] &&
        this.props['expectedAnswers'][index].value == this.questionDetails.answers[index][0].value) {
        return "good-answer";
      } else {
        // Bad Answer
        const correctAnswer = this.initWords.find((option) => option.value === this.props['expectedAnswers'][index].value);
        if (this.questionDetails.answers[index].length == 0) {
          this.questionDetails.answers[index][0] = {}
        }
        this.questionDetails.answers[index][0]['answer'] = (this.questionDetails.answers[index][0].value ? "  → " : "") + (correctAnswer ? correctAnswer.label : " ");
        return "bad-answer";
      }
    }
    return []
  }

}
